import { useState, useEffect } from "react";
import { getApiUrl } from "../config/api";
import "../App.css";

function HomeScreen() {
    // State for header scroll effect
    const [isScrolled, setIsScrolled] = useState(false);
    
    // State for FAQ items
    const [activeFaqIndex, setActiveFaqIndex] = useState(null);

    // Handle scroll effect
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Handle email submission
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const input = form.querySelector('input[type="email"]');
        const button = form.querySelector('.btn-primary');
        const email = input.value.trim();

        if (!email) {
            alert('Please enter an email address');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address');
            return;
        }

        try {
            button.disabled = true;
            button.innerHTML = 'Submitting... <i class="fas fa-spinner fa-spin"></i>';

            const apiBaseUrl = getApiUrl();
            const response = await fetch(`${apiBaseUrl}/v1/api/email/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                input.value = '';
                button.innerHTML = 'Submitted! <i class="fas fa-check"></i>';

                setTimeout(() => {
                    button.disabled = false;
                    button.innerHTML = 'Get Started <i class="fas fa-chevron-right"></i>';
                }, 2000);
            } else {
                throw new Error('Failed to submit email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit email. Please try again.');
            button.disabled = false;
            button.innerHTML = 'Get Started <i class="fas fa-chevron-right"></i>';
        }
    };

    return (
        <div className="App">
            {/* Header Section */}
            <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
                <nav className="navbar">
                    <div className="logo">
                        <span>[ Yellow ]</span>
                    </div>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-content">
                    <h1>What's your favorite book to watch</h1>
                    <form className="email-signup" onSubmit={handleEmailSubmit}>
                        <input type="email" placeholder="Email address" required />
                        <button type="submit" className="btn-primary">
                            Get Started <i className="fas fa-chevron-right"></i>
                        </button>
                    </form>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="faq">
                <div className="faq-container">
                    <div className="faq-content">
                        <h2 style={{ lineHeight: 1 }}>Frequently Asked Questions</h2>
                        <div className="faq-list">
                            {[
                                {
                                    question: "What is Yellow?",
                                    answer: "YELLOW is a multimedia platform that combines literature and film within a single book, using both traditional film and AI-generated film. Readers can effortlessly switch between reading and watching through QR codes that unlock film chapters, creating an immersive storytelling experience."
                                },
                                {
                                    question: "How does it work?",
                                    answer: "Each YELLOW book features QR codes that, when scanned, unlock professionally produced film segments that correspond to key moments in the story. These film chapters bring the story to life while maintaining the depth of a traditional book."
                                },
                                {
                                    question: "What can I read and watch now?",
                                    answer: <>
                                        <p><i>The Tahara Project</i> is the first release under YELLOW and the first-ever TV series in a book. This new age drama with twist and turns is a groundbreaking hybrid of literature and film designed to showcase the power of our book-to-film format.</p>
                                        <p>Sign up and join 5,000 + early readers ready for the first-ever Tv series in a book set to release this fall.</p>
                                    </>
                                }
                            ].map((faq, index) => (
                                <div 
                                    key={index} 
                                    className={`faq-item ${activeFaqIndex === index ? 'active' : ''}`}
                                >
                                    <button 
                                        className="faq-question"
                                        onClick={() => setActiveFaqIndex(activeFaqIndex === index ? null : index)}
                                    >
                                        {faq.question} <i className="fas fa-plus"></i>
                                    </button>
                                    <div className="faq-answer">
                                        {typeof faq.answer === 'string' ? <p>{faq.answer}</p> : faq.answer}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="faq-image">
                        <img src="/images/lady_with_book.png" alt="Lady with Book" />
                    </div>
                </div>
            </section>

            {/* Email Section */}
            <section id="email-section" className="email-section">
                <div className="email-container">
                    <div className="email-content">
                        <p>Try our sample story, <i>Susanna Maybe</i>?</p>
                        <form className="email-signup" onSubmit={handleEmailSubmit}>
                            <input type="email" placeholder="Email address" required />
                            <button type="submit" className="btn-primary">
                                Get Started <i className="fas fa-chevron-right"></i>
                            </button>
                        </form>
                    </div>
                    <div className="email-image">
                        <img src="/images/overlayed_image_2.png" alt="" />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-company">
                        <span>Yellow Multimedia Corporation</span>
                    </div>
                    <div className="footer-links">
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6ff7ecb7-7af1-4292-9a62-b5424a317db3">Terms and Conditions</a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7e5b5bf2-c604-4638-954f-fc5887e5665b">Privacy Policy</a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=85e9cfe5-899f-4c70-b0da-671607d8db63">Cookie Policy</a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default HomeScreen;